<template>
  <div>
    <div class="image-input image-input-outline bg-secondary">
      <div
        v-if="currentUser.avatar"
        class="image-input-wrapper avatar"
        :style="'background-image: url(' + currentUser.avatar + ')'"
      ></div>
      <div v-else class="image-input-wrapper avatar d-flex justify-content-center align-items-end">
        <img src="/media/svg/avatars/001-boy.svg" class="h-75" alt="" />
      </div>

      <label
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="change"
        data-toggle="tooltip"
        data-original-title="Change avatar"
        @click="launchFilePicker()"
      >
        <i class="fa fa-pen icon-sm text-muted"></i>
      </label>

      <span
        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
        data-action="remove"
        data-toggle="tooltip"
        title="Remove avatar"
        @click="removeAvatar()"
      >
        <i class="ki ki-bold-close icon-xs text-muted"></i>
      </span>
    </div>

    <!-- slot for parent component to activate the file changer -->

    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      ref="file"
      type="file"
      :name="uploadFieldName"
      style="display: none"
      @change="onFileChange($event.target.name, $event.target.files)"
    />
    <!-- error dialog displays any potential error messages -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ImageInput',
  props: {
    // Use "value" to enable using v-model
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      avatar: null,
      uploadFieldName: 'avatar',
      maxSize: 1024
    };
  },
  computed: mapGetters(['currentUser']),

  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    async onFileChange(fieldName, file) {
      const { maxSize } = this;
      let imageFile = file[0];
      if (file.length > 0) {
        let size = imageFile.size / maxSize / maxSize;
        if (!imageFile.type.match('image/jpeg|image/png')) {
          // check whether the upload is an image
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Selecione uma imagem válida, nos formatos permitidas png,jpg.'
          });
        } else if (size > 1) {
          // check whether the size is greater than the size limit
          this.$swal({
            icon: 'error',
            title: 'Oops...',
            text: 'Seu arquivo é muito grande! Selecione uma imagem com menos de 1 MB.'
          });
        } else {
          // Append file into FormData and turn file into image URL
          let formData = new FormData();
          let imageURL = URL.createObjectURL(imageFile);
          formData.append(fieldName, imageFile);
          // Emit the FormData and image URL to the parent component
          this.avatar = { formData, imageURL };

          this.$swal({
            title: 'Você tem certeza que deseja atualizar sua foto de perfil ?',
            html:
              '<div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">' +
              '<div class="symbol-label" style="background-image:url(' +
              this.avatar.imageURL +
              ')"></div>' +
              '</div>',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: false,
            confirmButtonColor: '#2e9e01',
            cancelButtonColor: '#cc2323',
            preConfirm: async () => {
              return await this.$store
                .dispatch('STORE_AVATAR', formData)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                icon: 'success',
                title: result.value.message
              });
            }
            this.$refs.file.value = null;
          });
        }
      }
    },
    removeAvatar() {
      this.$swal({
        title: 'Você tem certeza que deseja remover sua foto de perfil?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: false,
        confirmButtonColor: '#2e9e01',
        cancelButtonColor: '#cc2323',
        preConfirm: async () => {
          return await this.$store
            .dispatch('DELETE_AVATAR')
            .then((response) => {
              return response;
            })
            .catch((error) => {
              this.$swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: result.value.message
          });
        }
        this.$refs.file.value = null;
      });
    }
  }
};
</script>
