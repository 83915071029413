<template>
  <div>
    <!--begin::User-->
    <div class="d-flex align-items-center flex-column">
      <AvatarImageInput />
      <div style="margin-top: -15px; z-index: 9">
        <span :class="'badge badge-' + currentStatus.class + ' font-size-h6'">{{ currentStatus.name }}</span>
      </div>
      <div class="d-flex align-items-center flex-column justify-content-center mt-5">
        <a href="#" class="font-weight-bolder font-size-h3 text-dark-75 text-hover-primary text-center">
          {{ nameLastname(currentCustomer.name) }}
        </a>
      </div>
    </div>
    <!--end::User-->
    <!--begin::Contact-->
    <div class="py-9">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Email:</span>
        <a href="#" class="text-muted text-hover-primary">{{ currentUser.email }}</a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">Data Cadastro:</span>
        <span class="text-muted">{{ $moment(currentCustomer.created_at).format('DD/MM/YYYY') }}</span>
      </div>
      <div v-if="currentAddress" class="d-flex align-items-center justify-content-between">
        <span class="font-weight-bold mr-2">Localização:</span>
        <span class="text-muted">{{ currentAddress.uf }}</span>
      </div>
    </div>
    <!--end::Contact-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AvatarImageInput from './AvatarImageInput';
export default {
  components: { AvatarImageInput },
  computed: mapGetters(['currentUser', 'currentStatus', 'currentCustomer', 'currentAddress'])
};
</script>

<style></style>
